var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"row mobile-categories hide-on-tablet hide-on-desktop"},[_c('div',{staticClass:"col-10"},[_c('ul',{staticClass:"nav listings-categories",attrs:{"id":"listing_cats"}},_vm._l((_vm.listingCategories),function(category){return _c('li',{key:category.id,class:category.id === _vm.currentCategory
              ? 'nav-item mobile-listing-cats active'
              : 'nav-item mobile-listing-cats'},[_c('a',{attrs:{"href":'/listings?category_id=' + category.id}},[_vm._v(" "+_vm._s(category.name)+" ")])])}),0)]),_vm._m(1)]),_c('hr'),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-2 col-lg-2 col-md-3 col-3 hide-on-mobile"},[_c('h3',[_vm._v("Categories")]),_c('hr'),_c('div',{staticClass:"row"},_vm._l((_vm.listingCategories),function(category){return _c('div',{key:category.id,class:category.id === _vm.currentCategory
              ? 'col-12 desktop-listing-cats active'
              : 'col-12 desktop-listing-cats'},[_c('router-link',{attrs:{"to":{
              name: 'listings',
              params: { category_id: category.id },
            }}},[_vm._v(" "+_vm._s(category.name)+" ")])],1)}),0)]),_c('div',{staticClass:"col-xl-9 col-lg-9 col-md-9 col-12"},[_c('div',{staticClass:"ls-center"},[_c('h2',[_vm._v(_vm._s(/*currentCategory*/))])]),(_vm.listings.length > 0)?_c('div',{staticClass:"row"},_vm._l((_vm.listings),function(listing){return _c('div',{key:listing.id,staticClass:"col-12"},[_c('router-link',{attrs:{"to":{ name: 'listing', params: { id: listing.id } }}},[_c('div',{staticClass:"row ls-listing-card"},[_c('div',{staticClass:"col-3 col-xs-12 listing_item_img"},[_c('img',{attrs:{"src":"/img/shop.png","alt":"Shop"}})]),_c('div',{staticClass:"col-9 col-xs-12 listing_item_info"},[_c('h4',[_vm._v(" "+_vm._s(listing.title)+" ")]),_c('div',[_vm._v(" "+_vm._s(listing.user.name)+" ")])])])])],1)}),0):_c('div',{staticClass:"ls-center"},[_c('h3',[_vm._v("No Listings Available")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ls-center hide-on-tablet hide-on-desktop"},[_c('p',[_c('i',[_vm._v("Categories")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-2"},[_c('i',{staticClass:"icon-chevron-down-circle",staticStyle:{"font-size":"30px"},attrs:{"id":"toggle_no_wrap"}})])
}]

export { render, staticRenderFns }