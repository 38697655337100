<template>
  <div>
    <div class="ls-center hide-on-tablet hide-on-desktop">
      <p><i>Categories</i></p>
    </div>
    <div class="row mobile-categories hide-on-tablet hide-on-desktop">
      <div class="col-10">
        <ul id="listing_cats" class="nav listings-categories">
          <li
              v-bind:class="
              category.id === currentCategory
                ? 'nav-item mobile-listing-cats active'
                : 'nav-item mobile-listing-cats'
            "
              v-for="category in listingCategories"
              :key="category.id"
          >
            <a :href="'/listings?category_id=' + category.id">
              {{ category.name }}
            </a>
          </li>
        </ul>
      </div>
      <div class="col-2">
        <i
            id="toggle_no_wrap"
            class="icon-chevron-down-circle"
            style="font-size:30px"
        ></i>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-xl-2 col-lg-2 col-md-3 col-3 hide-on-mobile">
        <h3>Categories</h3>
        <hr />
        <!-- List of Categories-->
        <div class="row">
          <div
              v-bind:class="
              category.id === currentCategory
                ? 'col-12 desktop-listing-cats active'
                : 'col-12 desktop-listing-cats'
            "
              v-for="category in listingCategories"
              :key="category.id"
          >
            <router-link
                :to="{
                name: 'listings',
                params: { category_id: category.id },
              }"
            >
              {{ category.name }}
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-xl-9 col-lg-9 col-md-9 col-12">
        <!-- The Listings for the Selected Category -->

        <div class="ls-center">
          <h2>{{ /*currentCategory*/ }}</h2>
        </div>
        <div class="row" v-if="listings.length > 0">
          <div class="col-12" v-for="listing in listings" :key="listing.id">
            <router-link :to="{ name: 'listing', params: { id: listing.id } }">
              <div class="row ls-listing-card">
                <!--Image -->
                <div class="col-3 col-xs-12 listing_item_img">
                  <img src="/img/shop.png" alt="Shop" />
                </div>
                <!--Listing details -->
                <div class="col-9 col-xs-12 listing_item_info">
                  <h4>
                    {{ listing.title }}
                  </h4>
                  <div>
                    {{ listing.user.name }}
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
        <div v-else class="ls-center">
          <h3>No Listings Available</h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "Listings",
  data() {
    return {
      listings: [],
      listingCategories: [],
      currentCategory: "",
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  watch: {
    $route(to, from) {
      this.currentCategory = this.$route.params.category_id
          ? this.$route.params.category_id
          : "1";
      this.getListings(this.currentCategory);
    },
  },
  mounted() {
    this.currentCategory = this.$route.params.category_id
        ? this.$route.params.category_id
        : "1";
    this.getListings(this.currentCategory);
    this.getCategories();

        
    $("#toggle_no_wrap").on("click", function() {
      $("#listing_cats").toggleClass("listings-categories");
      $(this).toggleClass("icon-chevron-down-circle");
      $(this).toggleClass("icon-chevron-up-circle");
    });
  },
  methods: {
    getListings(cat_id) {
      this.isLoading = true;
      axios.get("listings?category_id=" + cat_id).then((response) => {
        this.listings.length = 0;
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].user.id === this.auth.user.id) {
            this.listings.push(response.data.data[i]);
          }
        }
        this.isLoading = false;
      });
    },

    getCategories() {
      this.isLoading = true;
      axios.get("listings/categories").then((response) => {
        this.listingCategories = response.data;
        this.getListings(this.listingCategories[0].id);
        this.isLoading = false;
      });
    },
  },
};
</script>
<style scoped>
.listing-image {
  max-width: 70px;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 1;
}
</style>
